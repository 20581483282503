<template>
  <div class="modal fade"
       id="modal-business-settings-form"
       tabindex="-1"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">{{ brand ? 'Edit' : 'Add' }}</h5>
<!--          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>-->
        </div><!--end modal-header-->
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Brand Name</label>
                <input :class="['form-control', {'is-invalid': errorFor('BrandName')}]"
                       v-model="form.BrandName"
                       type="text">
                <v-errors :errors="errorFor('BrandName')" />
              </div>
              <div class="form-group">
                <label>Logo(s)</label>
                <input :class="['form-control', {'is-invalid': errorFor('Logo')}]"
                       v-model="form.Logo"
                       type="text">
                <v-errors :errors="errorFor('Logo')" />
              </div>
              <div class="form-group">
                <label>Brand Colors</label>
                <div style="width: 100%">
                  <color-picker class="form-control"
                                format="hex"
                                v-model:pureColor="form.BrandColor" />
                </div>
              </div>
              <div class="form-group">
                <label>Percentage Off in discount</label>
                <input :class="['form-control', {'is-invalid': errorFor('PercentageOff')}]"
                       v-model="form.PercentageOff"
                       type="text">
                <v-errors :errors="errorFor('PercentageOff')" />
              </div>
              <div class="form-group">
                <label>Brand Category</label>
                <select :class="['form-control', {'is-invalid': errorFor('BrandCategory')}]"
                       v-model="form.BrandCategory"
                       type="text">
                  <option value="">Select Category</option>
                  <option value="Category A">Category A</option>
                </select>
                <v-errors :errors="errorFor('BrandCategory')" />
              </div>
              <div class="form-group">
                <label>Store Product Type</label>
                <input :class="['form-control', {'is-invalid': errorFor('BrandProductType')}]"
                       v-model="form.BrandProductType"
                       type="text">
                <v-errors :errors="errorFor('BrandProductType')" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Attribution Link</label>
                <select class="select2 form-control"
                        id="select2-links"
                        style="width: 100%"
                        multiple="multiple"
                        data-placeholder="Select Links">
                  <option :value="campaign.id"
                          v-for="campaign in campaigns"
                          :key="'campaign_'+campaign.id">{{ campaign.influencer_name }}</option>
                </select>
              </div>
              <div class="form-group"
                   v-for="product in form.products"
                   :key="'product_'+product.amazon_ad_id">
                <label>Product Name - {{ product.campaign_name }}</label>
                <input class="form-control"
                       :disabled="product.fetchingData"
                       v-model="product.product_name"
                       type="text">
              </div>
              <div class="form-group">
                <label>Brand Photos</label>
                <select class="select2 form-control"
                        id="select2-brand-photos"
                        style="width: 100%"
                        multiple="multiple">
                </select>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <img class="mb-2 mr-2"
                       :src="brand"
                       @error="imageLoadOnError"
                       height="80"
                       v-for="(brand, i) in form.BrandPhotos"
                       :key="'brand_'+i" />
                </div>
              </div>

              <div class="form-group">
                <label>Promo code</label>
                <input :class="['form-control', {'is-invalid': errorFor('PromoCode')}]"
                       v-model="form.PromoCode"
                       type="text">
                <v-errors :errors="errorFor('PromoCode')" />
              </div>
              <div class="form-group">
                <label>Brand Sign-Off</label>
                <input :class="['form-control', {'is-invalid': errorFor('SignOff')}]"
                       v-model="form.SignOff"
                       type="text">
                <v-errors :errors="errorFor('SignOff')" />
              </div>
              <div class="form-group">
                <label>Store Attribution Link</label>
                <input :class="['form-control', {'is-invalid': errorFor('StoreLink')}]"
                       v-model="form.StoreLink"
                       type="text">
                <v-errors :errors="errorFor('StoreLink')" />
              </div>
            </div>
          </div>
        </div><!--end modal-body-->
        <div class="modal-footer">
          <button type="button"
                  :disabled="loading"
                  @click="hide"
                  class="btn btn-secondary btn-sm me-1">Close</button>
          <button type="button"
                  :disabled="loading"
                  @click="submit"
                  class="btn btn-success btn-sm">
            <i class="fas fa-save"></i> Add
          </button>
        </div>
      </div><!--end modal-content-->
    </div><!--end modal-dialog-->
  </div><!--end modal-->
</template>

<script>
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
export default {
  components: { ColorPicker },
  data() {
    return {
      campaigns: [],
      modal: null,
      brand: null,
      fetchingProduceName: false,
      form: {
        products: [],
        BrandName: '',
        amazon_ads: '',
        Logo: '',
        BrandPhotos: [],
        BrandColor: '#4cb050',
        PromoCode: '',
        PercentageOff: '',
        SignOff: '',
        BrandCategory: '',
        StoreLink: '',
        BrandProductType: '',
        AttributionLink: '',
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-business-settings-form'), {
      backdrop: 'static'
    });
    this.loadCampaigns();
    // this.initBrandPhotosSelect2();
  },
  methods: {
    resetForms() {
      this.form = {
        products: [],
        BrandName: '',
        amazon_ads: '',
        Logo: '',
        BrandPhotos: [],
        BrandColor: '#4cb050',
        PromoCode: '',
        PercentageOff: '',
        SignOff: '',
        BrandCategory: '',
        StoreLink: '',
        BrandProductType: '',
        AttributionLink: '',
      };
    },
    initBrandPhotosSelect2() {
      let self = this;

      /*if (window.$('#select2-brand-photos').hasClass("select2-hidden-accessible")) {
        window.$('#select2-brand-photos').select2('destroy');
      }*/

      window.$('#select2-brand-photos').select2({
        tokenSeparators: [",", "\t", "\n"],
        tags: true,
        allowClear: false,
      }).on('select2:open', function () {
        window.$('.select2-container--open .select2-dropdown--below').css('display','none');
      });

      self.form.BrandPhotos.forEach(photo => {
        if (window.$('#select2-brand-photos').find("option[value='" + photo + "']").length) {
          window.$('#select2-brand-photos').val(photo).trigger('change');
        } else {
          // Create a DOM Option and pre-select by default
          let newOption = new Option(photo, photo, true, true);
          // Append it to the select
          window.$('#select2-brand-photos').append(newOption).trigger('change');
        }
      })


      window.$('#select2-brand-photos').val(self.form.BrandPhotos).trigger('change');

      window.$('#select2-brand-photos').on('change', function () {
        self.form.BrandPhotos = window.$("#select2-brand-photos").select2("val");
      });

      this.showLists = true;
    },
    initLinksSelect2() {
      let self = this;

      /*if (window.$('#select2-links').hasClass("select2-hidden-accessible")) {
        window.$('#select2-links').select2('destroy');
      }*/

      window.$('#select2-links').select2();

      window.$('#select2-links').val(self.form.amazon_ads).trigger('change');

      window.$('#select2-links').on('change', function () {
        const newValues = window.$("#select2-links").select2("val");

        newValues.forEach(nv => {
          const exists = self.form.products.find(p => p.amazon_ad_id == nv);
          const campaign = self.campaigns.find(p => p.id == nv);

          if (!exists) {
            self.form.products.push({
              amazon_ad_id: nv,
              campaign_name: campaign.influencer_name,
              product_name: '',
              fetchingData: false
            });

            self.fetchingProductName(nv);
          }
        });

        self.form.products.forEach((product, i) => {
          if (!newValues.includes(product.amazon_ad_id)) {
            self.form.products.splice(i, 1);
          }
        })

        self.form.amazon_ads = window.$("#select2-links").select2("val");
      });

      this.showLists = true;
    },
    show(brand = null) {
      this.brand = brand;

      if (brand) {
        this.form = {...brand};
      } else {
        this.form = {
          products: [],
          BrandName: '',
          amazon_ads: '',
          Logo: '',
          BrandPhotos: [],
          BrandColor: '#4cb050',
          PromoCode: '',
          PercentageOff: '',
          SignOff: '',
          BrandCategory: '',
          StoreLink: '',
          BrandProductType: '',
          AttributionLink: '',
        };
      }

      this.modal.show();

      setTimeout(() => {
        this.initBrandPhotosSelect2();
        this.initLinksSelect2();
      }, 300);
    },
    hide() {
      window.$('#select2-links').val([]).trigger('change');
      window.$('#select2-brand-photos').val([]).trigger('change');

      if (window.$('#select2-links').hasClass("select2-hidden-accessible")) {
        window.$('#select2-links').select2('destroy');
      }

      if (window.$('#select2-brand-photos').hasClass("select2-hidden-accessible")) {
        window.$('#select2-brand-photos').select2('destroy');
      }

      this.resetForms();
      this.modal.hide();
    },
    loadCampaigns() {
      this.campaigns = [];

      this.axios.get('/business-settings/campaigns')
          .then(res => {
            this.campaigns = res.data.data;
          })
    },
    fetchingProductName(amazonAdId) {
      const campaign = this.campaigns.find(p => p.id == amazonAdId);
      let product = this.form.products.find(p => p.amazon_ad_id == amazonAdId);

      product.fetchingData = true;

      this.axios.get('/amazon/product/'+campaign.influencer_asin+'/US')
          .then(res => {
            if (res.data.data) {
              let product = this.form.products.find(p => p.amazon_ad_id == amazonAdId);

              if (product) {
                product.product_name = res.data.data.title;

              }
            }
          })
          .finally(() => {
            if (product) {
              product.fetchingData = false;
            }
          })
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      if (this.brand) {
        this.axios.patch('/business-settings/brands/' + this.brand.id, this.form)
            .then(() => {
              this.hide();
              this.$emit('added');
            })
            .catch((err) => {
              this.allErrors = err.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            })
      } else {
        this.axios.post('/business-settings/brands', this.form)
            .then(() => {
              this.hide();
              this.$emit('added');
            })
            .catch((err) => {
              this.allErrors = err.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            })
      }
    }
  }
}
</script>

<style>
.vc-color-wrap {
  width: 30% !important;
}
</style>