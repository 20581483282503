<template>
  <page-title-component title="Keyword Ranking" />

  <div class="box box-shadow bg-white mb-4 p-3 rounded">
    <div class="row">
      <div class="col-md-4">
        <select class="form-control"
                v-model="form.amazonAdId"
                @change="changeCampaignHandler">
          <option value="">Select Campaign</option>
          <option :value="campaign.id"
                  v-for="campaign in campaigns"
                  :key="'campaign_'+campaign.id">{{ formatCampaignName(campaign.amazon_campaign) }}</option>
        </select>
      </div>
      <div class="col-md-4">
        <select class="form-control" v-model="form.keywordId">
          <option value="">Select Keyword</option>
          <option :value="keyword.id"
                  v-for="keyword in keywords"
                  :key="'keyword_'+keyword.id">{{ keyword.keyword }}</option>
        </select>
      </div>
      <div class="col-md-4">
        <button class="btn btn-primary"
                @click="fetchRankData"
                :disabled="!form.keywordId || !form.amazonAdId">View</button>
      </div>
    </div>
  </div>

  <div class="alert alert-info mt-2"
       v-if="!loading && !campaigns.length"
       role="alert">
    <i class="fas fa-info"></i> No keywords are being tracked at the moment. You can track a keyword by going to a Google campaign's keyword drill down page and selecting 'Start Tracking'.
  </div>

  <div class="box box-shadow bg-white mb-4 p-3 rounded" v-show="chartData">
    <div class="row">
      <div class="col-12" style="height: 400px">
        <canvas id="rank-chart" class="drop-shadow" height="40" width="100%"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent";

export default {
  components: {PageTitleComponent},
  data() {
    return {
      chart: null,
      loading: false,
      chartData: null,
      form: {
        amazonAdId: '',
        keywordId: ''
      },
      campaigns: [],
      keywords: []
    }
  },
  created() {
    this.loadCampaigns();

    if (this.$route.query.id) {
      this.form.keywordId = parseInt(this.$route.query.id);

      this.fetchRankData();
    }
  },
  methods: {
    changeCampaignHandler() {
      this.form.keywordId = '';

      if (this.form.amazonAdId) {
        this.keywords = this.campaigns.find(cam => cam.id === this.form.amazonAdId).keywords.filter(k => k.is_checking);
      } else {
        this.keywords = [];
      }
    },
    loadCampaigns() {
      this.loading = true;

      this.axios.get('/keyword-rank/campaigns')
          .then(res => {
            this.campaigns = res.data.data;

            if (this.$route.query.id) {
              let keywordId = parseInt(this.$route.query.id);

              this.campaigns.forEach(cam => {
                cam.keywords.forEach(key => {
                  if (key.id === keywordId) {
                    this.form.amazonAdId = cam.id;
                  }
                })
              })

              this.changeCampaignHandler();
              this.form.keywordId = keywordId;
            }
          })
          .finally(() => {
            this.loading = false;
          })
    },
    fetchRankData() {
      if (this.chart) {
        this.chart.destroy();
        this.chartData = null;
      }

      this.axios.get('/keyword-rank/' + this.form.keywordId)
          .then((res) => {
            this.initChart(res.data.data);
          });
    },
    initChart(data) {
      this.chartData = data;
      const ctx = document.getElementById('rank-chart').getContext('2d');
      this.chart = new window.Chart(ctx, {
        type: 'line',
        plugins: [window.ChartDataLabels],
        data: {
          labels:data.labels,
          datasets: [{
            label: 'Rank',
            data: data.data,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              formatter: function(value) {
                return '#' + value;
              },
              font: {
                weight: 'bold',
              }
            }
          },
          scales: {
            y: {
              reverse: true,
              ticks: {
                padding: 20,
                stepSize: 1
              }
            },
            x: {
              ticks: {
                padding: 20
              }
            }
          }
        }
      });
    }
  }
}
</script>