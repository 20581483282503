<template>
  <page-title-component title="Business Settings" />

  <div class="row">
    <div class="col-md-12 layout-spacing">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic">
            <div class="portlet-title">
              <div class="p-caption">
                <div class="row">
                  <div class="col-6">
                    <div class="custom-control custom-checkbox pl-0">
                      <input type="checkbox"
                             v-model="business_draft_only_mode"
                             @change="saveDraftModeOnly"
                             value="1"
                             class="custom-control-input"
                             id="draftMode">
                      <label class="custom-control-label"
                             for="draftMode">Draft Only Mode</label>
                    </div>
                  </div>
                  <div class="col-6 text-right">
                    <a href="#"
                       @click.prevent="$refs.formModal.show()"
                       class="btn btn-sm btn-primary">Add</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="portlet-body py-4">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>Brand Name</th>
                  <th class="text-right">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="brand in brands"
                    :key="'brand_'+brand.id">
                  <td>{{ brand.BrandName }}</td>
                  <td class="text-right">
                    <router-link class="btn btn-sm btn-warning"
                                 :to="{name: 'business_settings_edit', params: {id: brand.id}}">Edit</router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form-modal @added="loadBrands" ref="formModal" />
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import FormModal from "@/views/business_settings/FormModal.vue";
import {mapGetters} from "vuex";

export default {
  components: {PageTitleComponent, FormModal},
  data() {
    return {
      brands: [],
      business_draft_only_mode: false,
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings'
    })
  },
  created() {
    this.loadBrands();
  },
  mounted() {
    this.business_draft_only_mode = this.settings.business_draft_only_mode;
  },
  methods: {
    saveDraftModeOnly() {
      this.$store.dispatch('settings/saveSettings', {
        business_draft_only_mode: this.business_draft_only_mode
      })
    },
    loadBrands() {
      this.brands = [];

      this.axios.get('/business-settings/brands')
          .then(res => {
            this.brands = res.data.data;
          })
    }
  }
}
</script>