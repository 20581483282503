<template>
  <page-title-component title="Brand - Edit" />

  <div class="row">
    <div class="col-md-12 layout-spacing">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic">
            <div class="portlet-body py-4">
              <div class="row" v-if="form">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Brand Name</label>
                    <input :class="['form-control', {'is-invalid': errorFor('BrandName')}]"
                           v-model="form.BrandName"
                           type="text">
                    <v-errors :errors="errorFor('BrandName')" />
                  </div>
                  <div class="form-group">
                    <label>Logo(s)</label>
                    <input :class="['form-control', {'is-invalid': errorFor('Logo')}]"
                           v-model="form.Logo"
                           type="text">
                    <v-errors :errors="errorFor('Logo')" />
                  </div>
                  <div class="form-group">
                    <label>Brand Colors</label>
                    <div style="width: 100%">
                      <color-picker class="form-control"
                                    format="hex"
                                    v-model:pureColor="form.BrandColor" />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Percentage Off in discount</label>
                    <input :class="['form-control', {'is-invalid': errorFor('PercentageOff')}]"
                           v-model="form.PercentageOff"
                           type="text">
                    <v-errors :errors="errorFor('PercentageOff')" />
                  </div>
                  <div class="form-group">
                    <label>Brand Category</label>
                    <select :class="['form-control', {'is-invalid': errorFor('BrandCategory')}]"
                            v-model="form.BrandCategory"
                            type="text">
                      <option value="">Select Category</option>
                      <option value="Category A">Category A</option>
                    </select>
                    <v-errors :errors="errorFor('BrandCategory')" />
                  </div>
                  <div class="form-group">
                    <label>Store Product Type</label>
                    <input :class="['form-control', {'is-invalid': errorFor('BrandProductType')}]"
                           v-model="form.BrandProductType"
                           type="text">
                    <v-errors :errors="errorFor('BrandProductType')" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Attribution Link</label>
                    <select class="select2 form-control"
                            id="select2-links"
                            style="width: 100%"
                            multiple="multiple"
                            data-placeholder="Select Links">
                      <option :value="campaign.id"
                              v-for="campaign in campaigns"
                              :key="'campaign_'+campaign.id">{{ campaign.influencer_name }}</option>
                    </select>
                  </div>
                  <div class="form-group"
                       v-for="product in form.products"
                       :key="'product_'+product.amazon_ad_id">
                    <label>Product Name - {{ product.campaign_name }}</label>
                    <input class="form-control"
                           :disabled="product.fetchingData"
                           v-model="product.product_name"
                           type="text">
                  </div>
                  <div class="form-group">
                    <label>Brand Photos</label>
                    <select class="select2 form-control"
                            id="select2-brand-photos"
                            style="width: 100%"
                            multiple="multiple">
                    </select>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <img class="mb-2 mr-2"
                           :src="brand"
                           @error="imageLoadOnError"
                           height="80"
                           v-for="(brand, i) in form.BrandPhotos"
                           :key="'brand_'+i" />
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Promo code</label>
                    <input :class="['form-control', {'is-invalid': errorFor('PromoCode')}]"
                           v-model="form.PromoCode"
                           type="text">
                    <v-errors :errors="errorFor('PromoCode')" />
                  </div>
                  <div class="form-group">
                    <label>Brand Sign-Off</label>
                    <input :class="['form-control', {'is-invalid': errorFor('SignOff')}]"
                           v-model="form.SignOff"
                           type="text">
                    <v-errors :errors="errorFor('SignOff')" />
                  </div>
                  <div class="form-group">
                    <label>Store Attribution Link</label>
                    <input :class="['form-control', {'is-invalid': errorFor('StoreLink')}]"
                           v-model="form.StoreLink"
                           type="text">
                    <v-errors :errors="errorFor('StoreLink')" />
                  </div>
                </div>
              </div>

              <hr>

              <div class="row">
                <div class="col-12 text-right">
                  <router-link :to="{name: 'business_settings'}"
                               class="btn btn-secondary btn-sm mr-1">Cancel</router-link>
                  <button type="button"
                          @click="submit"
                          class="btn btn-success btn-sm">
                    <i class="fas fa-save"></i> Save
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import {mapGetters} from "vuex";
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

export default {
  components: {PageTitleComponent, ColorPicker},
  data() {
    return {
      form: null,
      campaigns: [],
    }
  },
  mounted() {
    this.loadCampaigns();
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings'
    })
  },
  methods: {
    loadCampaigns() {
      this.campaigns = [];

      this.axios.get('/business-settings/campaigns')
          .then(res => {
            this.campaigns = res.data.data;
            this.loadBrand();
          })
    },
    loadBrand() {
      this.axios.get('/business-settings/brands/' + this.$route.params.id)
          .then(res => {
            this.form = res.data.data;

            setTimeout(() => {
              this.initBrandPhotosSelect2();
              this.initLinksSelect2();
            }, 300);
          })
    },
    initBrandPhotosSelect2() {
      let self = this;

      window.$('#select2-brand-photos').select2({
        tokenSeparators: [",", "\t", "\n"],
        tags: true,
        allowClear: false,
      }).on('select2:open', function () {
        window.$('.select2-container--open .select2-dropdown--below').css('display','none');
      });

      self.form.BrandPhotos.forEach(photo => {
        if (window.$('#select2-brand-photos').find("option[value='" + photo + "']").length) {
          window.$('#select2-brand-photos').val(photo).trigger('change');
        } else {
          // Create a DOM Option and pre-select by default
          let newOption = new Option(photo, photo, true, true);
          // Append it to the select
          window.$('#select2-brand-photos').append(newOption).trigger('change');
        }
      })


      window.$('#select2-brand-photos').val(self.form.BrandPhotos).trigger('change');

      window.$('#select2-brand-photos').on('change', function () {
        self.form.BrandPhotos = window.$("#select2-brand-photos").select2("val");
      });

      this.showLists = true;
    },
    initLinksSelect2() {
      let self = this;

      window.$('#select2-links').select2();

      window.$('#select2-links').val(self.form.amazon_ads).trigger('change');

      window.$('#select2-links').on('change', function () {
        const newValues = window.$("#select2-links").select2("val");

        newValues.forEach(nv => {
          const exists = self.form.products.find(p => p.amazon_ad_id == nv);
          const campaign = self.campaigns.find(p => p.id == nv);

          if (!exists) {
            self.form.products.push({
              amazon_ad_id: nv,
              campaign_name: campaign.influencer_name,
              product_name: '',
              fetchingData: false
            });

            self.fetchingProductName(nv);
          }
        });

        self.form.products.forEach((product, i) => {
          if (!newValues.includes(product.amazon_ad_id)) {
            self.form.products.splice(i, 1);
          }
        })

        self.form.amazon_ads = window.$("#select2-links").select2("val");
      });

      this.showLists = true;
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.patch('/business-settings/brands/' + this.$route.params.id, this.form)
          .then(() => {
            this.$router.push({name: 'business_settings'})
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
    }
  }
}
</script>